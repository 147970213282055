<template>
  <div>
    <el-dialog
        title="预约到店"
        width="40%"
        :visible.sync="state"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="客户名">
              <el-input readonly v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="到店日期">
              <el-input readonly v-model="form.appointDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="礼服师" prop="appointDressId">
              <el-select v-model.number="form.appointDressId" placeholder="礼服师" clearable>
                <el-option
                    v-for="item in appointDressArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="化妆师">
              <el-select v-model.number="form.appointCosmeticsId" placeholder="化妆师" clearable>
                <el-option
                    v-for="item in appointCosmeticsArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="房间名称" prop="roomId">
              <el-select v-model.number="form.roomId" placeholder="房间名称" clearable>
                <el-option
                    v-for="item in roomArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="close">取 消</el-button>
        <el-button type="primary" size="medium" @click="arrSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "app-arrival",
  created() {
    this.pageInit()
    console.log(this.form)
  },
  props: {
    appArrivalState: {
      type: Boolean,
      default: false,
      required: true,
    },
    app: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      state: this.appArrivalState,
      form: {...this.app},
      appointDressArray: [],
      appointCosmeticsArray: [],
      roomArray: [],
      rules: {
        appointDressId: [{required: true, message: "请选择礼服师", trigger: "change"}],
        roomId: [{required: true, message: "请选择房间", trigger: "change"}]
      },
    }
  },
  methods: {
    pageInit() {
      this.$selectUtils.queryDressIds().then(response => {
        this.appointDressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryCosmeticsIds().then(response => {
        this.appointCosmeticsArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryRoomIdsByShopId(this.form.shopId).then(response => {
        this.roomArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryOrderProceedsProjects().then(response => {
        this.proceedsNameArray = JSON.parse(response.data.data)
      })
    },
    close() {
      this.$emit("close");
    },
    arrSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.appointDress = this.form.appointDressId
          this.form.appointCosmetics = this.form.appointCosmeticsId
          this.form.room = this.form.roomId
          this.$axios({
            method: "PUT",
            url: "/appoint/arrivalAppoint",
            params: {
              id: this.form.id,
              cusId: this.form.cusId,
              appointDress: this.form.appointDress,
              appointCosmetics: this.form.appointCosmetics,
              room: this.form.room,
              appState: this.form.appStateId,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '到店分配成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            this.close();
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>