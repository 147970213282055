<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row>
        <el-button-group>
          <el-button type="primary" size="medium" v-for="item in dateArray" @click="dateChange(item)">
            {{item}}
          </el-button>
        </el-button-group>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="3">
          <el-select v-model="search.appointShop" placeholder="预约店铺" clearable size="medium">
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-date-picker type="date" placeholder="到店日期"
                          v-model="search.appointDate"
                          value-format="yyyy-MM-dd"
                          size="medium"
                          style="width: 100%;">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.type" size="medium" placeholder="预约类型" clearable>
            <el-option
                v-for="item in appTypeArray"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="queryAppList" size="medium">搜索</el-button>
          <el-button type="warning" @click="reset" size="medium">重置</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-left: 10px;margin-top: 10px">
        <i class="el-icon-date" style="font-size: 30px"> {{search.appointDate}} {{dayOfWeek}}的预约</i>
    </el-row>
    <el-row style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="appointScheduleArray"
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="590"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          style="width: 99%">
        <el-table-column prop="name" label="客户名" align="center" width="120" fixed show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="150"></el-table-column>
        <el-table-column prop="weChat" label="微信号" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="appointDate" label="到店日期" align="center" width="120"></el-table-column>
        <el-table-column prop="appointTime" label="到店时间" sortable align="center" width="120"></el-table-column>
        <el-table-column prop="appointName" label="预约项目" align="center" width="120"></el-table-column>
        <el-table-column prop="appStateName" label="预约状态" align="center" width="150"></el-table-column>
        <el-table-column prop="appointShop" label="预约店铺" align="center" width="120"></el-table-column>
        <el-table-column prop="service" label="客服" align="center" width="120"></el-table-column>
        <el-table-column prop="type" label="预约类型" align="center" width="120"></el-table-column>
        <el-table-column label="电话确认" align="center" width="120">
          <template slot-scope="scope">
            <el-switch
                v-model="scope.row.affirm"
                active-value="1"
                inactive-value="0"
                @change="switchChange(scope.row)"
                active-color="#13ce66">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isValid" label="到店" align="center" width="120">
          <template slot-scope="scope">
            <span :style="{color: scope.row.isValid === '1' ? 'yellowgreen' :scope.row.isValid === '2' ? '#a5673f' : 'red'}">
              {{scope.row.isValid === "1" ? "未到店" :scope.row.isValid === "2" ? "已到店" : "取消预约"}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="appointDress" label="礼服师" align="center" width="120"></el-table-column>
        <el-table-column prop="appointCosmetics" label="化妆师" align="center" width="120"></el-table-column>
        <el-table-column prop="room" label="房间" align="center" width="120"></el-table-column>
        <el-table-column prop="inviter" label="预约人" align="center" width="120"></el-table-column>
        <el-table-column prop="sourceName" label="来源渠道" align="center" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="预约备注" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="180" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <per-button type="warning" size="mini" @click="appArrivalOpen(scope.row)" per="app_schedule:arrival">到店</per-button>
              <per-button type="danger" size="mini" @click="cancel(scope.row)" per="app_schedule:cancel">取消</per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>

    <appArrival :app="app" :appArrivalState="appArrivalState" v-if="appArrivalState" @close="appArrivalClose"></appArrival>
  </div>
</template>

<script>
import appArrival from "@/pages/appoint/list/app-arrival"

export default {
  name: "appoint-schedule-list",
  created() {
    this.pageInit()
  },
  components: {
    appArrival
  },
  data() {
    return {
      search: {
        appointShop: "",
        appointDate: this.$dateUtils.getDateStr(0),
        type: ""
      },
      dayOfWeek: this.$dateUtils.getWeekDay(new Date()),
      dateArray: [],
      shopArray: [],
      appTypeArray:[
        {value: "售前预约"},
        {value: "售后预约"},
      ],
      total: 0,
      page: 1,
      limit: 100,
      appointScheduleArray: [],
      tenantCrop: localStorage.getItem("tenantCrop"),
      app: {},
      appArrivalState: false,
      loading: false
    }
  },
  methods: {
    pageInit() {
      this.getAfterWeekDays();
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.shopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
      this.queryAppList()
    },
    getAfterWeekDays() {
      for (let i = 1; i < 8; i++) {
        this.dateArray.push(this.$dateUtils.getDateStr(i))
      }
    },
    dateChange(val) {
      this.search.appointDate = val
      this.dayOfWeek=this.$dateUtils.getWeekDay(val);
      this.queryAppList()
    },
    isChange() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
    },
    queryAppList() {
      this.loading = true
      this.$axios({
        method: "GET",
        url: "/appoint/appointScheduleList",
        params: {
          ...this.search,
          limit: this.limit,
          page: this.page,
          tenantCrop: this.tenantCrop,
          shopIds: localStorage.getItem("shops"),
        }
      }).then(response => {
        this.appointScheduleArray = response.data.data.list
        this.total = response.data.data.total
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryAppList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryAppList()
    },
    appArrivalOpen(data) {
      if (data.isValid === "3") {
        this.$message({
          message: '当前预约已取消，不可进行改操作',
          type: 'warning',
          duration: 1500,
          showClose: true,
        });
        return
      }
      this.app = data
      this.appArrivalState = true
    },
    cancel(data) {
      if (data.isValid === "2") {
        this.$message({
          message: '当前预约已到店，不可进行取消',
          type: 'warning',
          duration: 1000,
          showClose: true,
        });
      } else if (data.isValid === "3") {
        this.$message({
          message: '当前预约已取消，请勿重复操作',
          type: 'warning',
          duration: 1000,
          showClose: true,
        });
      } else {
        this.$alert("确认要取消 <b>"+ data.name +"</b> 的这条预约吗？"
            ,'取消预约',
            {
              dangerouslyUseHTMLString: true,
              type: "warning",
              showCancelButton: true
            }).then(() => {
          this.$axios({
            method: "POST",
            url: "/appoint/cancelAppointById",
            params: {
              id: data.id,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '取消预约成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            this.queryAppList()
          })
        }).catch(()=>{})
      }
    },
    switchChange(val) {
      console.log(val)
      this.$axios({
        method: "POST",
        url: "/appoint/updateAffirmById",
        params: {
          id: val.id,
          affirm: val.affirm
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '修改成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.queryAppList();
      })
    },
    appArrivalClose() {
      this.appArrivalState = false
      this.queryAppList()
    },
    reset() {
      Object.keys(this.search).forEach(key=>{this.search[key]=''})
    },
  }
}
</script>

<style scoped>

</style>